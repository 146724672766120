


import request from './request'

//添加订单 所有用户都可添加
export function addOrder(data) {
    return request({
        url: '/api/Order/AddOrder',
        method: 'POST',
        data:data,
    })
}

//删除订单 高级管理员
export function deleteOrder(data) {
    return request({
        url: '/api/Order/DeleteOrder',
        method: 'POST',
        data:data,
    })
}

//获取所有订单 按Time倒序 高级管理员与被授予查看权限的用户
export function getAllOrder() {
    return request({
        url: '/api/Order/GetAllOrder',
        method: 'GET',
    })
}

//获取订单列表（分页） 按Time倒序 高级管理员与被授予查看权限的用户
export function getOrderList(data) {
    return request({
        url: '/api/Order/GetOrderList',
        method: 'POST',
        data:data,
    })
}

//修改订单 高级管理员
export function updateOrder(data) {
    return request({
        url: '/api/Order/UpdateOrder',
        method: 'POST',
        data:data,
    })
}

//导出全部查询内容为excel
export function exportExcelOrder(data) {
    return request({
        url: '/api/Order/ExportExcelOrder',
        method: 'POST',
        data:data,
        responseType:"blob",
    })
}

//导出全部查询内容为txt
export function exportTextOrder(data) {
    return request({
        url: '/api/Order/ExportTextOrder',
        method: 'POST',
        data:data,
        responseType:"blob",
    })
}







