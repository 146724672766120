/************************************************** 导入包 **************************************************/
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
//Vue.use(ElementPlus, { size: 'mini', zIndex: 3000 })

// const fixElTableErr=table=>{
//   const oldResizeListener=table.methods.resizeListener
//   table.methods.resizeListener=()=>{
//     window.requestAnimationFrame(oldResizeListener.bind(this))
//   }
// }
// fixElTableErr(Table)
app.use(ElementPlus, {locale: navigator.language=="zh-CN"? zhCn:en, })

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


import router from './router'
app.use(router)


import './styles/common.css'

/************************************************** 使用包 **************************************************/

//全局lodash工具
window._ = require('lodash')

app.mount('#app')
