<!-- 产品 -->
<template>
    <div ref="refProduct" style="width:100%;height:100%">
        <div style="padding:10px;height: 30px;margin-left: 10px;">
            <el-button type="primary" style="float:left" @click="addClick">
                新增
            </el-button>
        </div>
        <el-table :data="tableData" border style="width:calc(100% - 50px);margin-left: 10px;max-height:800px;">
            <el-table-column prop="ProductName" label="产品名称" width="180" />
            <el-table-column prop="Remark" label="备注" />
            <el-table-column prop="CreateTime" label="创建时间" width="180" />

            <el-table-column v-if="userInfo.IsManager" fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click="deleteClick(scope.row)">删除</el-button>
                    <el-button link type="primary" size="small" @click="editClick(scope.row)">修改</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="padding-top:10px;margin-left: 10px;">
            <el-pagination v-model:current-page="queryData.PageIndex" v-model:page-size="queryData.PageSize"
                layout="total, prev, pager, next, jumper" :total="queryData.TotalCount" @current-change="pageChange" />
        </div>

        <el-dialog v-model="dialogVisible" :title="editType ? '修改' : '新增'" width="500" :before-close="handleClose">
            <el-form ref="refForm" :model="formData" :rules="formRules">
                <el-form-item label="产品名称" label-width="120px" :disabled="editType" prop="ProductName">
                    <el-input v-model="formData.ProductName" />
                </el-form-item>
                <el-form-item label="备注" label-width="120px">
                    <el-input v-model="formData.Remark" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveForm" :disabled="formLoad">
                        提交
                    </el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>


<script>
import * as ProductApi from "../../apis/product.js";
export default {
    data() {
        return {
            userInfo: {
                IsManager: false,
                Right: 0,
            },
            queryData: {
                PageIndex: 1,
                PageSize: 20,
                TotalCount: 0,
            },
            tableData: [],
            tableHeight: 500,
            showEdit: true,
            editType: false,
            formLoad: false,
            dialogVisible: false,
            formData: {
                Id: "",
                ProductName: "",
                Remark: "",
            },
            formRules: {
                ProductName: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 16, message: '产品名称长度至多为16', trigger: 'blur' },
                    { min: 2, message: '产品名称长度至少为2', trigger: 'blur' },
                ],
            }
        };
    },
    created() {
    },
    unmounted() {
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem("UserInfo"))
        this.resizeData();
        this.getData();
    },
    methods: {
        pageChange() {
            this.getData();
        },
        resizeData() {
            this.tableWidth = this.$refs.refProduct.offsetWidth - 50;
        },
        addClick() {
            this.dialogVisible = true;
            this.editType = false;
            this.formData = {
                ProductName: "",
                Remark: "",
            }
        },
        saveForm() {
            this.formLoad = true;
            this.$refs.refForm.validate(valid => {
                if (!valid) {
                    this.formLoad = false;
                    return
                }
                let result = null;
                if (this.editType) {
                    result = ProductApi.updateProduct(this.formData)
                } else {
                    result = ProductApi.addProduct(this.formData)
                }
                result.then(res => {
                    if (res.data.Success) {
                        this.$message({
                            type: "success",
                            message: `提交成功！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                        this.handleClose();
                        this.getData();
                    } else {
                        this.$message({
                            type: "error",
                            message: `提交失败！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                    }
                }).finally(e => {
                    this.formLoad = false;
                })
            })
        },
        handleClose() {
            this.dialogVisible = false;
        },
        deleteClick(a) {
            ProductApi.deleteProduct([a.Id] ).then(res => {
                if (res.data.Success) {
                    this.$message({
                        type: "success",
                        message: `删除成功！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                    this.getData();
                } else {
                    this.$message({
                        type: "error",
                        message: `删除失败！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                }
            })
        },
        editClick(a) {
            this.dialogVisible = true;
            this.editType = true;
            this.formData = {
                Id: a.Id,
                ProductName: a.ProductName,
                Remark: a.Remark,
            }
        },
        getData() {
            ProductApi.getProductList(this.queryData).then(res => {
                this.queryData.PageIndex = res.data.ResultData.PageIndex;
                this.queryData.TotalCount = res.data.ResultData.TotalCount;
                this.queryData.PageSize = res.data.ResultData.PageSize;
                this.tableData = res.data.ResultData.ResultData;
            })
        }
    }
};
</script>

<style></style>


