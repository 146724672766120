
import LoginPage from '../views/login/index.vue'
import HomePage from '../views/home.vue'
import UserManagePage from '../views/userManage/index.vue'
import OrderPage from '../views/order/index.vue'
import AddOrderPage from '../views/order/addOrder.vue'
import ProductPage from '../views/product/index.vue'
import SmsPage from '../views/sms/index.vue'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        title: '登录页',
        requireAuth: false
      }
    },
    {
      path: '/addOrder',
      name: 'addOrder',
      component: AddOrderPage,
      meta: {
        title: '添加订单',
        requireAuth: false
      }
    },
  ]
});


router.beforeEach((to, from, next) => {  
  if (to.path === "/login" || to.path === "/"||to.path === "/addorder") {
    next()
  } else {
    let userInfoString = localStorage.getItem("UserInfo");
    if (userInfoString) {
      if (router.getRoutes().length > 3) {
        console.log(router.getRoutes());
        next()
      } else {
        let userInfo = JSON.parse(userInfoString)
        let children = [{
          path: '/user',
          name: 'user',
          component:UserManagePage,
          meta: {
            title: '用户管理页',
            requireAuth: true
          }
        }]
        if (userInfo.IsManager) {
          children.push({
            name: 'product',
            path: '/product',
            component: ProductPage,
            meta: {
              title: '产品管理',
              requireAuth: true
            },
          })
        }
        if (userInfo.LookOrder) {
          children.push({
            name: 'order',
            path: '/order',
            component: OrderPage,
            meta: {
              title: '订单管理',
              requireAuth: true
            },
          })
        }
        // if (userInfo.IsSmsManager) {
          children.push({
            name: 'sms',
            path: '/sms',
            component: SmsPage,
            meta: {
              title: '短信管理',
              requireAuth: true
            },
          })
        // }
        let pageRouter = {
          name: 'home',
          path: '/home',
          component:HomePage,
          meta: {
            title: '主页',
            requireAuth: false
          },
          children: children
        }
        localStorage.setItem("Router",JSON.stringify(pageRouter))
        //动态添加可访问路由表
        router.addRoute(pageRouter);
        next({path:`${to.path}`})
      }
    } else {
      next('/login')
    }
  }
})

//重置路由（解决多个角色依次登录时，路由表会叠加的问题）
export function resetRouter() {
  router.removeRoute("home");
}

export default router;