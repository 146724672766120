
import request from './request'
const apiUrl = "/api/Sms/"


//查询总量余量
export function smsCount() {
    return request({
        url: apiUrl + 'SmsCount',
        method: 'GET',
    })
}



//执行sms方法
export function smsAction(data) {
    return request({
        url: apiUrl + 'SmsAction',
        method: 'POST',
        data: data,
    })
}

//发送消息
export function sendMessage(data) {
    return request({
        url: apiUrl + 'SendMessage',
        method: 'POST',
        data: data,
    })
}

export const AliSmsActionEnum =
{
    // 发送信息
    SendSmsWithOptions: 0,
    // 批量发送信息
    SendBatchSmsWithOptions: 1,
    // 查询短信发送统计信息:
    QuerySendStatisticsWithOptions: 2,
    // 查询发送信息详情
    QuerySendDetailsWithOptions: 3,
    // 添加签名
    AddSmsSignWithOptions: 4,
    // 删除签名
    DeleteSmsSignWithOptions: 5,
    // 修改签名
    ModifySmsSignWithOptions: 6,
    // 查询签名列表
    QuerySmsSignListWithOptions: 7,
    // 查询签名状态
    QuerySmsSignWithOptions: 8,
    // 添加模板
    AddSmsTemplateWithOptions: 9,
    // 删除模板
    DeleteSmsTemplateWithOptions: 10,
    // 修改未通过模板
    ModifySmsTemplateWithOptions: 11,
    // 查询模板列表
    QuerySmsTemplateListWithOptions: 12,
    // 查询模板状态
    QuerySmsTemplateWithOptions: 13,
}