<template>
  <div class="header" style="margin-top: 1px;">
    <!-- Logo -->
    <div style="width:200px;text-align: center;margin:10px 0;background-color: darkslateblue;">
      <div style="line-height:51px;float:right;font-size:18px;margin-right:10px;">订单管理中心</div>
    </div>
    <div style="position: absolute;right:10px;top:20px">
      <div style="line-height:51px;float:right;font-size:18px;margin-right:10px;">
        <el-button link type="primary" size="small" @click="deleteClick">注销</el-button>
      </div>
      <div style="line-height:51px;float:right;font-size:18px;margin-right:10px;">{{ userInfo.UserName }}</div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      userInfo: {
        UserName: "",
        Right: 0,
      },
      routes: []
    };
  },
  components: {
  },
  created() {
  },
  computed: {},
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("UserInfo"))
  },
  methods: {
    //过滤router集合
    even: function (routerList) {
      return routerList.filter(function (item) {
        return item.meta.level === 1;
      });
    },
    deleteClick(){
      //Token
      localStorage.removeItem("Token");
      //用户信息
      localStorage.removeItem("UserInfo");
      //路由缓存
      localStorage.removeItem("Router")
      this.$router.replace("/");

    }

  }
};
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  border: #264787 100px;
  border-bottom: 1px #264787;
  background-color: darkslateblue;
}
</style>
