


import request from './request'

//添加用户 高级管理员才能赋予权限，普通管理员添加的用户也为普通管理员
export function addUser(data) {
    return request({
        url: '/api/User/AddUser',
        method: 'POST',
        data:data,
    })
}

//删除用户 非高级管理员无权删除，非Admin不能删除高级管理员
export function deleteUser(data) {
    return request({
        url: '/api/User/DeleteUser',
        method: 'POST',
        data:data,
    })
}

//获取所有用户
export function getAllUser() {
    return request({
        url: '/api/User/GetAllUser',
        method: 'GET',
    })
}

//获取用户列表（分页）
export function getUserList(data) {
    return request({
        url: '/api/User/GetUserList',
        method: 'POST',
        data:data,
    })
}

//修改用户 非高级管理员无权修改，非Admin不能修改高级管理员
export function updateUser(data) {
    return request({
        url: '/api/User/UpdateUser',
        method: 'POST',
        data:data,
    })
}
//修改密码 非自身或高级管理员无权修改，非Admin不能修改高级管理员
export function editPassword(data) {
    return request({
        url: '/api/User/EditPassword',
        method: 'POST',
        data:data,
    })
}

