<template>
  <div class="wrapper">
    <v-head></v-head>
    <div class="center">
      <div class="left">
        <!-- 动态菜单 -->
        <el-menu :default-active="$route.path" class="menu" background-color="#0b131d" text-color="#fff"
          active-text-color="#ffd04b" router>
          <el-menu-item v-for="(item,index) in routes" :key="'menu_'+index" :index="item.path">
            <span>{{ item.meta.title }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="content">
        <!-- <keep-alive> -->
        <router-view></router-view>
        <!-- </keep-alive> -->
      </div>
      <div style="clear: both;display: none;"></div>
    </div>
  </div>
</template>

<script>
import vHead from "./header.vue";
//import router from "../../router";

export default {
  data() {
    return {
      userInfo: {
        Right: 0,
      },
      routes: []

    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("UserInfo"))
    this.routes = JSON.parse(localStorage.getItem("Router")).children
    debugger
  },
  components: {
    vHead,
  }
};
</script>
<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  background-size: 10px 10px;
}

.center {
  position: absolute;
  top: 70px;
  width: 100%;
  height: calc(100% - 70px);
}

.left {
  width: 200px;
  height: 100%;
  float: left;
  .menu {
    height: 100%;
  }
}

.content {
  width: calc(100% - 200px);
  position: absolute;
  float: left;
  right: 0;
  height: 100%;
}
</style>