<!-- 用户管理 -->
<template>
    <div ref="refUser" style="width:100%;height:100%">
        <div style="padding:10px;height: 30px;margin-left: 10px;">
            <el-button type="primary" style="float:left" @click="addClick">
                新增
            </el-button>
        </div>
        <el-table :data="tableData" border style="width:calc(100% - 50px);margin-left: 10px;max-height:800px;">
            <el-table-column prop="UserName" label="用户名" />
            <el-table-column prop="Telephone" label="电话" />
            <el-table-column label="权限">
                <template #default="scope">
                    {{ ((scope.row.Right & 1) ? "高级管理员,查看订单" : ((scope.row.Right & 2) ? "查看订单" : "无")) }}
                </template>
            </el-table-column>
            <el-table-column label="产品">
                <template #default="scope">
                    {{ getProductName(scope.row.ProductIdList) }}
                </template>
            </el-table-column>

            <el-table-column prop="CreateTime" label="创建时间" width="180" />
            <el-table-column fixed="right" label="操作" width="180">
                <template #default="scope">
                    <el-button link type="primary"
                        v-show="scope.row.Id == userInfo.UserId || userInfo.IsAdmin || (userInfo.IsManager && !(scope.row.Right & 1))"
                        size="small" @click="editClick(scope.row)">修改</el-button>
                    <el-button link type="primary"
                        v-show="scope.row.Id == userInfo.UserId || userInfo.IsAdmin || (userInfo.IsManager && !(scope.row.Right & 1))"
                        size="small" @click="editPasswordClick(scope.row)">修改密码</el-button>
                    <el-button link type="primary"
                        v-show="scope.row.Id == userInfo.UserId || userInfo.IsAdmin || (userInfo.IsManager && !(scope.row.Right & 1))"
                        size="small" @click="deleteClick(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="padding-top:10px;margin-left: 10px;">
            <el-pagination v-model:current-page="queryData.PageIndex" v-model:page-size="queryData.PageSize"
                layout="total, prev, pager, next, jumper" :total="queryData.TotalCount" @current-change="pageChange" />
        </div>

        <el-dialog v-model="dialogVisible" :title="editType ? '修改' : '新增'" width="500" :before-close="handleClose">
            <el-form ref="refForm" :model="formData" :rules="formRules">
                <el-form-item label="名称" label-width="120px" prop="UserName">
                    <el-input v-model="formData.UserName" :disabled="editType" />
                </el-form-item>
                <el-form-item label="密码" label-width="120px" v-if="!editType" prop="Password">
                    <el-input v-model="formData.Password" autocomplete="off" />
                </el-form-item>
                <el-form-item label="电话" label-width="120px">
                    <el-input v-model="formData.Telephone" autocomplete="off" />
                </el-form-item>
                <el-form-item label="授权" v-show="userInfo.IsManager" label-width="120px">
                    <el-checkbox v-model="formData.Right1" :disabled="!userInfo.IsAdmin" @change="changeRight1"
                        label="高级管理员" />
                    <el-checkbox v-model="formData.Right2" :disabled="!userInfo.IsManager || formData.Right1"
                        @change="changeRight2" label="查看订单" />
                    <el-checkbox v-model="formData.Right3" :disabled="!userInfo.IsAdmin" label="短信管理" />
                </el-form-item>
                <el-form-item label="产品" v-show="userInfo.IsManager" label-width="120px">
                    <el-checkbox-group v-model="formData.ProductIdList" :disabled="!formData.Right2">
                        <el-checkbox v-model="formData.Right1" v-for="item in productList" :key="item.Id" :label="item.Id">
                            {{ item.ProductName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveForm" :disabled="formLoad">
                        提交
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="dialogEditPasswordVisible" title="修改密码" width="500">
            <el-form ref="refForm" :model="formData.Password" :rules="formRules">
                <el-form-item label="密码" label-width="120px" prop="Password">
                    <el-input v-model="formData.Password" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogEditPasswordVisible = false">取消</el-button>
                    <el-button type="primary" @click="editPassword"> 提交 </el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>


<script>
import * as UserApi from "../../apis/user.js";
import * as ProductApi from "../../apis/product.js";
export default {
    data() {
        return {
            userInfo: {
                IsAdmin: false,
                IsManager: false,
                Right: 0,
            },
            queryData: {
                PageIndex: 1,
                PageSize: 20,
                TotalCount: 0,
            },
            tableData: [],
            productList: [],
            tableWidth: 500,
            editType: false,
            formLoad: false,
            dialogVisible: false,
            dialogEditPasswordVisible: false,
            formData: {
                UserName: "",
                Password: "",
                Telephone: "",
                Right: 0,
                Right1: false,
                Right2: false,
                Right3: false,
                ProductIdList: []
            },
            formRules: {
                UserName: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 16, message: '名称长度至多为16', trigger: 'blur' },
                    { min: 2, message: '名称长度至少为2', trigger: 'blur' },
                ],
                Password: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 16, message: '密码长度至多为16', trigger: 'blur' },
                    { min: 6, message: '密码长度至少为6', trigger: 'blur' },
                ],
            }
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem("UserInfo"))
        this.resizeData();
        this.loadProduct();
        this.getData();
    },
    methods: {
        pageChange() {
            this.getData();
        },
        resizeData() {
            this.tableWidth = this.$refs.refUser.offsetWidth - 50;
        },
        addClick() {
            this.dialogVisible = true;
            this.editType = false;
            this.formData = {
                UserName: "",
                Password: "",
                Telephone: "",
                Right: 0,
                Right1: false,
                Right2: false,
                Right3: false,
                ProductIdList: [],
            }
        },
        saveForm() {
            this.formLoad = true;
            this.$refs.refForm.validate(valid => {
                if (!valid) {
                    this.formLoad = false;
                    return
                }
                this.formData.Right = (this.formData.Right1 ? 1 : 0) + (this.formData.Right2 ? 2 : 0) + (this.formData.Right3 ? 4 : 0);
                let result = null;
                if (this.editType) {
                    result = UserApi.updateUser(this.formData)
                } else {
                    result = UserApi.addUser(this.formData)
                }
                result.then(res => {
                    if (res.data.Success) {
                        this.$message({
                            type: "success",
                            message: `提交成功！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                        this.handleClose();
                        this.getData();
                    } else {
                        this.$message({
                            type: "error",
                            message: `提交失败！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                    }
                }).finally(e => {
                    this.formLoad = false;
                })
            })
        },
        handleClose() {
            this.dialogVisible = false;
        },
        deleteClick(data) {
            UserApi.deleteUser({ ids: [data.Id] }).then(res => {
                if (res.data.Success) {
                    this.$message({
                        type: "success",
                        message: `删除成功！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                    this.getData();
                } else {
                    this.$message({
                        type: "error",
                        message: `删除失败！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                }
            })
        },
        changeRight1() {
            if (this.formData.Right1) {
                this.formData.Right2 = true;
            }
        },
        changeRight2() {
            if (!this.formData.Right2) {
                this.formData.ProductIdList = []
            }
        },
        editClick(data) {
            this.dialogVisible = true;
            this.editType = true;
            debugger
            this.formData = {
                Id: data.Id,
                UserName: data.UserName,
                Password: "",
                Telephone: data.Telephone,
                Right: data.Right,
                Right1: (data.Right & 1) > 0,
                Right2: (data.Right & 2) > 0,
                Right3: (data.Right & 4) > 0,
                ProductIdList: data.ProductIdList
            }
        },
        editPasswordClick(data) {
            this.formData.Id = data.Id;
            this.formData.Password = "";
            this.dialogEditPasswordVisible = true;
        },
        editPassword() {
            UserApi.editPassword({ Id: this.formData.Id, Password: this.formData.Password }).then(res => {
                if (res.data.Success) {
                    this.$message({
                        type: "success",
                        message: `提交成功！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: `提交失败！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                }
            }).finally(res => {
                this.dialogEditPasswordVisible = false;
            })
        },
        getData() {
            UserApi.getUserList(this.queryData).then(res => {
                this.queryData.PageIndex = res.data.ResultData.PageIndex;
                this.queryData.TotalCount = res.data.ResultData.TotalCount;
                this.queryData.PageSize = res.data.ResultData.PageSize;
                this.tableData = res.data.ResultData.ResultData;
            })
        },
        getProductName(productIdList) {
            let result = this.productList.filter(t => productIdList.findIndex(i => i == t.Id) >= 0).map(t => t.ProductName)
            return result.join(",")
        },
        loadProduct() {
            ProductApi.getAllProduct().then(res => {
                this.productList = res.data.ResultData;
            })
        },
    }
};
</script>

<style></style>






