import axios from "axios";
import apiUrl from "../../public/config.js";

const service = axios.create({
    baseURL: apiUrl,//"http://184.168.22.178:8080",//"https://localhost:7008/",//
    timeout: 60000,  // 请求超时时间
});
export default service


//请求拦截器
service.interceptors.request.use(
    config => {
        let token = localStorage.getItem("Token")
        //判断是否存在token，存在 则每个http header都加上token
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);


// 响应拦截器
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
            this.$message({
                message: '请求超时！',
                type: 'error'
            });
            originalRequest._retry = true;
            return Promise.reject(error.response.data)
        }
        if (error.response) {
            this.$message({
                message: '应用程序发生错误,请联系管理员!',
                type: 'error'
            });
            return Promise.reject(error.response.data)
        }
    }
);