<!-- 订单 -->
<template>
    <div ref="refOrder" style="width:100%;height:100%;">
        <div style="padding:10px;height: 30px;margin-left: 10px;display: flex;justify-content: space-between;">
            <el-button type="primary" style="" @click="addClick">
                新增
            </el-button>
            <div>

                <el-button type="primary" style="" @click="exportExcelOrder" title="导出所有查询条件内容">
                    导出Excel
                </el-button>
                <el-button type="primary" style="" @click="exportTextOrder" title="导出所有查询条件内容">
                    导出Text
                </el-button>
                <el-input v-model="queryData.QueryString" style="width: 200px;margin:0px 30px 0 12px;" clearable
                    @clear="reloadData" placeholder="搜索账号或姓名">
                    <template #append>
                        <el-button @click="searchData">
                            <Search />
                        </el-button>
                    </template>
                </el-input>
            </div>

        </div>
        <el-table :data="tableData" border style="width:calc(100% - 50px);margin-left: 10px;max-height:800px;">
            <el-table-column label="产品" fixed="left" width="180">
                <template #default="scope">
                    {{ getProductName(scope.row.ProductId) }}
                </template>
            </el-table-column>
            <el-table-column prop="OrderString" label="订单号(ID)" fixed="left" width="180" />
            <el-table-column prop="LastName" label="姓" width="100" />
            <el-table-column prop="FirstName" label="名" width="100" />
            <el-table-column prop="Telephone" label="电话" width="150" />
            <el-table-column prop="Email" label="邮箱" width="180">
                <template #default="scope">
                    <el-tooltip class="box-item" effect="dark" :content="scope.row.Address" placement="top-start">
                        <el-label style="white-space: nowrap;text-overflow: ellipsis;overflow-wrap: break-word;">
                            {{ scope.row.Address }}
                        </el-label>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="Country" label="国家" width="180" />
            <el-table-column prop="Province" label="州/省" width="180" />
            <el-table-column prop="City" label="城市" width="180" />
            <el-table-column label="地址" width="200">
                <template #default="scope">
                    <el-tooltip class="box-item" effect="dark" :content="scope.row.Address" placement="top-start">
                        <el-label style="white-space: nowrap;text-overflow: ellipsis;overflow-wrap: break-word;">
                            {{ scope.row.Address }}
                        </el-label>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="PostCode" label="邮编" width="120" />
            <el-table-column prop="Account" label="帐号" width="180" />
            <el-table-column label="时间" width="180">
                <template #default="scope">
                    {{ formatDate(scope.row.Time, "YYYY-MM") }}
                </template>
            </el-table-column>
            <el-table-column prop="CCode" label="C码" width="180" />
            <el-table-column prop="InvitationCode" label="验证码" width="180" />
            <el-table-column prop="Type" label="类型" width="180" />
            <el-table-column prop="Attribute" label="属性" width="180" />
            <el-table-column prop="Ip" label="IP" width="180" />
            <el-table-column prop="Language" label="语言" width="180" />
            <el-table-column label="浏览器信息" width="500">
                <template #default="scope">
                    <el-tooltip class="box-item" effect="dark" :content="scope.row.UserAgent" placement="top-start">
                        <el-label style="white-space: nowrap;text-overflow: ellipsis;overflow-wrap: break-word;">
                            {{ scope.row.UserAgent }}
                        </el-label>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="Remark" label="备注" width="180" />
            <el-table-column prop="CreateTime" label="创建时间" width="180" />
            <el-table-column v-show="userInfo.IsManager" fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click="deleteClick(scope.row)">删除</el-button>
                    <el-button link type="primary" size="small" @click="editClick(scope.row)">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="padding-top:10px;margin-left: 10px;">
            <el-pagination v-model:current-page="queryData.PageIndex" v-model:page-size="queryData.PageSize"
                layout="total, prev, pager, next, jumper" :total="queryData.TotalCount" @current-change="pageChange" />
        </div>

        <el-dialog v-model="dialogVisible" :title="editType ? '修改' : '新增'" width="800" style="max-height:800px;"
            :before-close="handleClose">
            <el-form ref="refForm" :inline="true" :model="formData" :rules="formRules">
                <el-row>
                    <el-form-item label="产品" prop="ProductId" label-width="120px">
                        <el-select v-model="formData.ProductId">
                            <el-option v-for="item in ProductList" :key="item.Id" :label="item.ProductName"
                                :value="item.Id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单号(ID)" prop="OrderString" label-width="120px">
                        <el-input v-model="formData.OrderString" :disabled="editType" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="姓" label-width="120px">
                        <el-input v-model="formData.LastName" />
                    </el-form-item>
                    <el-form-item label="名" label-width="120px">
                        <el-input v-model="formData.FirstName" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="电话" label-width="120px">
                        <el-input v-model="formData.Telephone" />
                    </el-form-item>
                    <el-form-item label="邮箱" label-width="120px">
                        <el-input v-model="formData.Email" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="国家" label-width="120px">
                        <el-input v-model="formData.Country" />
                    </el-form-item>
                    <el-form-item label="州/省" label-width="120px">
                        <el-input v-model="formData.Province" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="城市" label-width="120px">
                        <el-input v-model="formData.City" />
                    </el-form-item>
                    <el-form-item label="地址" label-width="120px">
                        <el-input v-model="formData.Address" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="邮编" label-width="120px">
                        <el-input v-model="formData.PostCode" />
                    </el-form-item>
                    <el-form-item label="帐号" label-width="120px">
                        <el-input v-model="formData.Account" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="时间" prop="Time" label-width="120px">
                        <el-date-picker v-model="formData.Time" type="month" placeholder="选择时间" />
                        <!-- format="YYYY-MM-DD HH:mm:ss"  -->
                    </el-form-item>
                    <el-form-item label="C码(数字)" label-width="120px">
                        <el-input type="number" v-model="formData.CCode" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="验证码" label-width="120px">
                        <el-input v-model="formData.InvitationCode" />
                    </el-form-item>
                    <el-form-item label="类型(字母)" label-width="120px">
                        <el-input v-model="formData.Type" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="属性" label-width="120px">
                        <el-input v-model="formData.Attribute" />
                    </el-form-item>
                    <el-form-item label="备注" label-width="120px">
                        <el-input v-model="formData.Remark" />
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveForm" :disabled="formLoad">
                        提交
                    </el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>


<script>
// import { Search } from '@element-plus/icons-vue'
import dayjs from "dayjs";
import * as OrderApi from "../../apis/order.js";
import * as ProductApi from "../../apis/product.js";
export default {
    data() {
        return {
            userInfo: {
                IsManager: false,
                Right: 0,
            },
            queryData: {
                PageIndex: 1,
                PageSize: 20,
                QueryString: "",
                TotalCount: 0,
            },
            ProductList: [],
            tableData: [],
            tableHeight: 500,
            tableWidth: 500,
            showEdit: true,
            editType: false,
            formLoad: false,
            dialogVisible: false,
            formData: {
                ProductId: "",
                OrderString: "",
                LastName: "",
                FirstName: "",
                Telephone: "",
                Email: "",
                Country: "",
                Province: "",
                City: "",
                Address: "",
                PostCode: "",
                Account: "",
                Year: "",
                Month: "",
                Day: "",
                Time: "",
                CCode: "",
                InvitationCode: "",
                Type: "",
                Attribute: "",
                Ip: "",
                Language: "",
                UserAgent: "",
                Remark: "",
            },
            formRules: {
                OrderString: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 100, message: '订单号长度至多为100', trigger: 'blur' },
                    { min: 2, message: '订单号长度至少为2', trigger: 'blur' },
                ],
                ProductId: [
                    { required: true, message: '必选', trigger: 'blur' },
                ],
                Time: [
                    { required: true, message: '必填', trigger: 'blur' },
                ],
            }
        };
    },
    created() {
    },
    unmounted() {
    },
    mounted() {
        this.resizeData();
        this.userInfo = JSON.parse(localStorage.getItem("UserInfo"))
        this.getProductList();
        this.getData();
    },
    methods: {
        pageChange() {
            this.getData();
        },
        searchData() {
            this.queryData.PageIndex = 1;
            this.getData();
        },
        resizeData() {
            this.tableWidth = this.$refs.refOrder.offsetWidth - 50;
        },
        addClick() {
            this.dialogVisible = true;
            this.editType = false;
            this.formData = {
                ProductId: "",
                OrderString: "",
                LastName: "",
                FirstName: "",
                Telephone: "",
                Email: "",
                Country: "",
                Province: "",
                City: "",
                Address: "",
                PostCode: "",
                Account: "",
                Time: "",
                CCode: "",
                InvitationCode: "",
                Type: "",
                Attribute: "",
                Ip: "",
                Language: "",
                UserAgent: "",
                Remark: "",
            }
        },
        saveForm() {
            this.formLoad = true;
            this.$refs.refForm.validate(valid => {
                if (!valid) {
                    this.formLoad = false;
                    return
                }
                let result = null;
                //加7天，防止不同时区导致
                this.formData.Time = dayjs(this.formData.Time).add(7, "day").toDate()
                if (this.editType) {
                    result = OrderApi.updateOrder(this.formData)
                } else {
                    result = OrderApi.addOrder(this.formData)
                }
                result.then(res => {
                    if (res.data.Success) {
                        this.$message({
                            type: "success",
                            message: `提交成功！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                        this.handleClose();
                        this.reloadData();
                    } else {
                        this.$message({
                            type: "error",
                            message: `提交失败！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                    }
                }).finally(e => {
                    this.formLoad = false;
                })
            })
        },
        handleClose() {
            this.dialogVisible = false;
        },
        deleteClick(a) {
            OrderApi.deleteOrder([a.Id]).then(res => {
                if (res.data.Success) {
                    this.$message({
                        type: "success",
                        message: `删除成功！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                    this.getData();
                } else {
                    this.$message({
                        type: "error",
                        message: `删除失败！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                }
            })
        },
        editClick(data) {
            this.dialogVisible = true;
            this.editType = true;
            this.formData = data
        },
        getProductName(productId) {
            debugger
            let product = this.ProductList.filter(t => t.Id == productId)
            if (product) {
                return product[0].ProductName
            } else {
                return ""
            }
        },
        reloadData() {
            this.queryData.PageIndex=1;
            this.queryData.QueryString = "";
            this.getData()
        },
        getData() {
            OrderApi.getOrderList(this.queryData).then(res => {
                this.queryData.PageIndex = res.data.ResultData.PageIndex;
                this.queryData.TotalCount = res.data.ResultData.TotalCount;
                this.queryData.PageSize = res.data.ResultData.PageSize;
                this.tableData = res.data.ResultData.ResultData;
            })
        },
        formatDate(val, fat) {
            if (!val) {
                return ''
            }
            return dayjs(val).format(fat)
        },
        exportExcelOrder() {
            OrderApi.exportExcelOrder(this.queryData).then(res => {
                let fileName = "订单列表" + dayjs().format("YYYYMMDDHHmmss") + ".xls"
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },
        exportTextOrder() {
            OrderApi.exportTextOrder(this.queryData).then(res => {
                let fileName = "订单列表" + dayjs().format("YYYYMMDDHHmmss") + ".txt"
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },
        getProductList() {
            ProductApi.getAllProduct().then(res => {
                this.ProductList = res.data.ResultData;
            })
        }

    }
};
</script>

<style></style>


