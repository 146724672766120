
import request from './request'

export function login(data) {
    data.loginKey=""
    return request({
      url: '/api/Login/Login',
      method: 'post',
      data:data,
    })
  }
  
export function getCurrentUser() {
    return request({
      url: '/api/Login/GetCurrentUser',
      method: 'get',
      params:{},
      data:{},
    })
  }

