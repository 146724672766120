<template>
    <div>
        <div style="padding: 20px;font-size: 36px;">添加订单</div>

        <el-form ref="refForm" :inline="true" :model="formData" :rules="formRules">
            <el-row>
                <el-form-item label="产品" prop="ProductId" label-width="120px">
                    <el-select v-model="formData.ProductId">
                        <el-option v-for="item in ProductList" :key="item.Id" :label="item.ProductName" :value="item.Id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="订单号(ID)" prop="OrderString" label-width="120px">
                    <el-input v-model="formData.OrderString" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="姓" label-width="120px">
                    <el-input v-model="formData.LastName" />
                </el-form-item>
                <el-form-item label="名" label-width="120px">
                    <el-input v-model="formData.FirstName" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="电话" label-width="120px">
                    <el-input v-model="formData.Telephone" />
                </el-form-item>
                <el-form-item label="邮箱" label-width="120px">
                    <el-input v-model="formData.Email" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="国家" label-width="120px">
                    <el-input v-model="formData.Country" />
                </el-form-item>
                <el-form-item label="州/省" label-width="120px">
                    <el-input v-model="formData.Province" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="城市" label-width="120px">
                    <el-input v-model="formData.City" />
                </el-form-item>
                <el-form-item label="地址" label-width="120px">
                    <el-input v-model="formData.Address" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="邮编" label-width="120px">
                    <el-input v-model="formData.PostCode" />
                </el-form-item>
                <el-form-item label="帐号" label-width="120px">
                    <el-input v-model="formData.Account" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="时间" prop="Time" label-width="120px">
                        <el-date-picker v-model="formData.Time" type="month" placeholder="选择时间"
                           />
                           <!-- format="YYYY-MM-DD HH:mm:ss"  -->
                </el-form-item>
                <el-form-item label="C码(数字)" label-width="120px">
                    <el-input type="number" v-model="formData.CCode" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="验证码" label-width="120px">
                    <el-input v-model="formData.InvitationCode" />
                </el-form-item>
                <el-form-item label="类型(字母)" label-width="120px">
                    <el-input v-model="formData.Type" />
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="属性" label-width="120px">
                    <el-input v-model="formData.Attribute" />
                </el-form-item>
                <el-form-item label="备注" label-width="120px">
                    <el-input v-model="formData.Remark" />
                </el-form-item>
            </el-row>
        </el-form>
        <div style="padding: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2.5rem;">
            <el-button @click="clearData" :disabled="formLoad">
                清空
            </el-button>
            <el-button type="primary" @click="saveForm" :disabled="formLoad">
                提交
            </el-button>
        </div>

    </div>
</template>

<script>
import * as OrderApi from "../../apis/order.js";
import * as ProductApi from "../../apis/product.js";

export default {
    data() {
        return {
            ProductList: [],
            formData: {
                ProductId: "",
                OrderString: "",
                LastName: "",
                FirstName: "",
                Telephone: "",
                Email: "",
                Country: "",
                Province: "",
                City: "",
                Address: "",
                PostCode: "",
                Account: "",
                Time: "",
                CCode: "",
                InvitationCode: "",
                Type: "",
                Attribute: "",
                Ip: "",
                Language: "",
                UserAgent: "",
                Remark: "",
            },
            formRules: {
                OrderString: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 100, message: '订单号长度至多为100', trigger: 'blur' },
                    { min: 2, message: '订单号长度至少为2', trigger: 'blur' },
                ],
                ProductId: [
                    { required: true, message: '必填', trigger: 'blur' },
                ],
                Time: [
                    { required: true, message: '必填', trigger: 'blur' },
                ],
            }
        };
    },
    mounted() {
        this.loadProduct()
    },
    methods: {
        clearData() {
            this.formData = {
                ProductId: "",
                OrderString: "",
                LastName: "",
                FirstName: "",
                Telephone: "",
                Email: "",
                Country: "",
                Province: "",
                City: "",
                Address: "",
                PostCode: "",
                Account: "",
                Time: "",
                CCode: "",
                InvitationCode: "",
                Type: "",
                Attribute: "",
                Ip: "",
                Language: "",
                UserAgent: "",
                Remark: "",
            }
        },
        saveForm() {
            this.$refs.refForm.validate(valid => {
                if (!valid) {
                    return
                }
                this.formLoad = true;
                let result = null;
                //加7天，防止不同时区导致
                this.formData.Time=dayjs(this.formData.Time).add(7,"day").toDate()
                if (this.editType) {
                    result = OrderApi.updateOrder(this.formData)
                } else {
                    result = OrderApi.addOrder(this.formData)
                }
                result.then(res => {
                    if (res.data.Success) {
                        this.$message({
                            type: "success",
                            message: `提交成功！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                        this.handleClose();
                        this.getData();
                    } else {
                        this.$message({
                            type: "error",
                            message: `提交失败！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                    }
                }).finally(e => {
                    this.formLoad = false;
                })
            })
        },
        loadProduct() {
            ProductApi.getAllProduct().then(res => {
                this.ProductList = res.data.ResultData;
            })
        }
    }
};


</script>

<style scoped>
.el-row {
    justify-content: center;
}
</style>
