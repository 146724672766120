<!-- 产品 -->
<template>
    <div style="width:100%;height:100%;">
        <el-row style="padding: 10px;text-align: left;">
            <el-col :span="4">
                短信总条数:{{ baseData.TotalCount }}条
            </el-col>
            <el-col :span="4">
                已使用条数:{{ baseData.UsedCount }}条
            </el-col>
            <el-col :span="4">
                剩余可用条数:{{ baseData.ResidueCount }}条
            </el-col>
        </el-row>
        <el-row style="width: 100%;">
            <el-tabs v-model="activeName" type="card" style="width: 100%;" @tab-change="tabsClick">
                <el-tab-pane v-if="showQianMing" label="签名" name="qianMing">
                </el-tab-pane>
                <el-tab-pane v-if="showMuBan" label="模板" name="muBan">
                </el-tab-pane>
                <el-tab-pane v-if="showFaSong" label="发送短信" name="faSong">
                </el-tab-pane>
            </el-tabs>
            <el-row style="padding:10px;width: 100%;">
                <el-col :span="4">
                    <div style="position: relative;height: 30px;">
                        <el-button style="left:10px;position: absolute;" type="primary" size="small" @click="addClick" :disabled="activeName == 'faSong' &&baseData.ResidueCount<=0">{{
                            activeName == "faSong" ? "发送短信" : (activeName == "qianMing" ? "添加签名" : (activeName == "muBan" ?
                                "添加模板" :
                                ""))
                        }}</el-button>
                    </div>
                </el-col>
                <el-col :span="20">
                    <div v-show="activeName == 'faSong'" style="position: relative;">
                        <el-input size="small" v-model="queryData.PhoneNumber" class="input-with-datepicker"
                            style="width: 400px;margin:0px 30px 0 12px;right:10px;position: absolute;" clearable
                            placeholder="接收短信的手机号码">
                            <template #prepend>
                                <el-date-picker v-model="queryData.QueryDate" style="width: 200px;" type="date"
                                    :clearable="false" placeholder="短信发送日期" format="YYYY-MM-DD" />
                            </template>
                            <template #append>
                                <el-button @click="searchData">
                                    <Search />
                                </el-button>
                            </template>
                        </el-input>
                    </div>

                </el-col>





            </el-row>
            <el-table :data="tableData" border style="width:calc(100% - 20px);margin-left: 10px;max-height:670px;">
                <template v-if="activeName == 'qianMing'">
                    <el-table-column prop="SignName" label="签名名称" width="150" />
                    <el-table-column prop="OrderId" label="工单号" width="120" />
                    <el-table-column prop="BusinessType" label="适用场景" width="120" />
                    <el-table-column prop="AuditStatus" label="审核状态" min-width="400">
                        <template #default="scope">
                            {{ signAuditState(scope.row.AuditStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="CreateDate" label="创建时间" width="400" />
                </template>
                <template v-if="activeName == 'muBan'">
                    <el-table-column prop="TemplateName" label="模板名称" width="150" />
                    <el-table-column prop="TemplateCode" label="模板CODE" width="150" />
                    <el-table-column prop="TemplateType" label="模板类型" width="120" />
                    <el-table-column prop="AuditStatus" label="审核状态" width="120">
                        <template #default="scope">
                            {{ signAuditState(scope.row.AuditStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="TemplateContent" label="模板内容" min-width="400" />
                </template>
                <template v-if="activeName == 'faSong'">
                    <el-table-column prop="PhoneNum" label="号码" width="120" />
                    <el-table-column prop="SendDate" label="发送时间" width="120" />
                    <el-table-column prop="ReceiveDate" label="接收时间" width="120" />
                    <el-table-column prop="TemplateCode" label="模板CODE" width="120" />
                    <el-table-column prop="Content" label="发送内容" min-width="400" />
                    <el-table-column prop="SendStatus" label="发送状态" width="120" />
                </template>
                <template v-if="activeName != 'faSong'">
                    <el-table-column fixed="right" label="操作" width="180">
                        <template #default="scope">
                            <el-button link type="primary"
                                v-show="(userInfo.IsAdmin || userInfo.IsSmsManager) && scope.row.AuditStatus != 'AUDIT_STATE_PASS'"
                                size="small" @click="editClick(scope.row)">
                                修改
                            </el-button>
                            <el-button link type="primary" v-show="userInfo.IsAdmin || userInfo.IsSmsManager" size="small"
                                @click="deleteClick(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
            <div style="padding-top:10px;margin-left: 10px;">
                <el-pagination v-model:current-page="queryData.PageIndex" v-model:page-size="queryData.PageSize"
                    layout="total, prev, pager, next, jumper" :total="queryData.TotalCount" @current-change="pageChange" />
            </div>
        </el-row>

        <el-dialog v-model="dialogVisible" :title="getTitle" width="800" :before-close="handleClose">
            <el-form ref="refForm" :model="formData" :rules="formRules">
                <template v-if="activeName == 'qianMing'">
                    <el-form-item label="签名名称" label-width="180px" :disabled="editType" prop="SignName">
                        <el-input v-model="formData.SignName" minlength="2" maxlength="12" show-word-limit="true" />
                    </el-form-item>
                    <el-form-item label="签名来源" label-width="180px" :disabled="editType" prop="SignSource">

                        <el-select v-model="formData.SignSource" style="width: 100%;">
                            <el-option v-for="item in signSource" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="签名类型" label-width="180px" :disabled="editType" prop="SignType">
                        <el-select v-model="formData.SignType" style="width: 100%;">
                            <el-option label="验证码" :value="0"></el-option>
                            <el-option label="通用" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="短信签名申请说明" label-width="180px" prop="Remark">
                        <el-input type="textarea" maxlength="200" show-word-limit="true" rows="8" resize="none"
                            placeholder="短信签名申请说明，长度不超过200个字符。  场景说明是签名审核的参考信息之一。请详细描述已上线业务的使用场景，并提供可以验证这些业务的网站链接、已备案域名地址、应用市场下载链接、公众号或小程序全称等信息。对于登录场景，还需提供测试账号密码。信息完善的申请说明会提高签名、模板的审核效率。"
                            v-model="formData.Remark" />
                    </el-form-item>
                </template>
                <template v-if="activeName == 'muBan'">
                    <el-form-item label="短信类型" label-width="180px" :disabled="editType" prop="TemplateType">
                        <el-select v-model="formData.TemplateType" style="width: 100%;">
                            <el-option v-for="item in templateType" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="模板名称" label-width="180px" prop="TemplateName">
                        <el-input v-model="formData.TemplateName" maxlength="30" show-word-limit="true" />
                    </el-form-item>
                    <el-form-item label="模板内容" label-width="180px" :disabled="editType" prop="TemplateContent">
                        <el-input type="textarea" maxlength="500" show-word-limit="true" rows="5" resize="none"
                            placeholder="长度不超过500个字符。目前仅支持1个验证码作为变量；4~6位仅数字、数字+字母组合或仅字母2种类型。示例值：您正在申请手机注册，验证码为：${code}，5分钟内有效！"
                            v-model="formData.TemplateContent" />
                    </el-form-item>
                    <el-form-item label="短信模板申请说明" label-width="180px" prop="Remark">
                        <el-input type="textarea" maxlength="100" show-word-limit="true" rows="2" resize="none"
                            placeholder="短信模板申请说明，是模板审核的参考信息之一。长度不超过100个字符。" v-model="formData.Remark" />
                    </el-form-item>
                </template>
                <template v-if="activeName == 'faSong'">
                    <el-form-item label="短信签名" label-width="120px" :disabled="editType" prop="SignName">
                        <el-select v-model="formData.SignName" style="width: 100%;">
                            <el-option v-for="item in signOptionData" :key="item.SignName" :label="item.SignName"
                                :value="item.SignName">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="短信模板" label-width="120px" :disabled="editType" prop="TemplateCode">
                        <el-select v-model="formData.TemplateCode" style="width: 100%;">
                            <el-option v-for="item in templateOptionData" :key="item.TemplateCode"
                                :label="item.TemplateName" :value="item.TemplateCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="模板参数" label-width="120px" :disabled="editType" prop="TemplateParam">
                        <el-input type="textarea" rows="2" resize="none"
                            placeholder='短信模板变量对应的实际值。没有则不填，示例：{"code":"123456"}。' v-model="formData.TemplateParam" />
                    </el-form-item>
                    <el-form-item label="接收号码" label-width="120px" :disabled="editType" prop="PhoneNumbers">
                        <el-input type="textarea" rows="10" resize="none"
                            placeholder="接收短信的手机号码。手机号码格式：国内短信：+/+86/0086/86或无任何前缀的11位手机号码，例如1390000****。支持对多个手机号码发送短信，手机号码之间以半角逗号（,）分隔。上限为1000个手机号码。批量调用相对于单条调用及时性稍有延迟。验证码类型短信，建议使用单独发送的方式。"
                            v-model="formData.PhoneNumbers" />
                    </el-form-item>
                </template>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveForm" :disabled="formLoad">
                        提交
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>


<script>
import dayjs from "dayjs";
import * as SmsApi from "../../apis/sms.js";
export default {
    data() {
        return {
            userInfo: {
                IsSmsManager: false,
                Right: 0,
            },
            signSource: [{ value: 0, name: "企事业单位的全称或简称" },
            { value: 1, name: "工信部备案网站的全称或简称" },
            { value: 2, name: "App应用的全称或简称" },
            { value: 3, name: "公众号或小程序的全称或简称" },
            { value: 4, name: "电商平台店铺名的全称或简称" },
            { value: 5, name: "商标名的全称或简称" },
            ],
            templateType: [{ value: 0, name: "验证码" }, { value: 1, name: "短信通知" }, { value: 2, name: "推广短信" },],
            // activeName: "qianMing",
            activeName: "faSong",
            tableData: [],
            signOptionData: [],
            templateOptionData: [],
            showQianMing:false,
            showMuBan:false,
            showFaSong:true,
            editType: false,
            formLoad: false,
            dialogVisible: false,
            queryData: {
                PageIndex: 1,
                PageSize: 15,
                PhoneNumber: "",
                QueryDate: dayjs().format("YYYY-MM-DD"),
                TotalCount: 0,
            },
            baseData: {
                Id: "",
                TotalCount: 0,
                UsedCount: 0,
                ResidueCount: 0,
                Model: "【温馨提示】:您的余额已不足，请及时充值。",
            },
            formData: {
                SignName: "",
                SignSource: "",
                SignType: "",

                TemplateCode: "",
                TemplateParam: "",
                PhoneNumbers: "",

                TemplateType: "",
                TemplateName: "",
                TemplateContent: "",
                Remark: "",
            },
            formRules: {
                TemplateType: [
                    { required: true, message: '必填', trigger: 'blur' },
                    // { pattern: /^([0-2])$/, message: '范围在0-2',trigger: 'blur'}
                ],
                TemplateName: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 30, message: '长度至多为30', trigger: 'blur' },
                    { min: 2, message: '长度至少为2', trigger: 'blur' },
                ],
                TemplateContent: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 500, message: '长度至多为500', trigger: 'blur' },
                ],
                Remark: [
                    { required: true, message: '必填', trigger: 'blur' },
                    { max: 200, message: '长度至多为200', trigger: 'blur' },
                ],
            },
        };
    },
    created() {
    },
    unmounted() {
    },
    computed: {
        getTitle() {
            let title = this.editType ? "修改" : "新增";
            switch (this.activeName) {
                case "qianMing":
                    title += "签名";
                    break;
                case "muBan":
                    title += "模板";
                    break;
                case "faSong":
                    title = "发送短信";
                    break;
                default:
                    break;
            }
            return title;
        },
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem("UserInfo"))
        this.tabsClick();
        this.getBaseData();
    },
    methods: {
        pageChange() {
            this.getData();
        },
        tabsClick() {
            this.queryData.PageIndex = 1;
            if (this.activeName != "faSong") {
                this.getData();
            } else {
                this.queryData.TotalCount = 0;
                this.tableData = []
            }
        },
        searchData() {
            this.queryData.PageIndex = 1;
            this.getData();
        },
        addClick() {
            switch (this.activeName) {
                case "qianMing":
                    this.formData = {
                        SignName: "",
                        SignSource: "",
                        SignType: "",
                        Remark: "",
                    }
                    break;
                case "muBan":
                    this.formData = {
                        TemplateType: "",
                        TemplateName: "",
                        TemplateContent: "",
                        Remark: "",
                    }
                    break;
                case "faSong":
                    this.formData = {
                        SignName: "",
                        TemplateCode: "",
                        TemplateParam: "",
                        PhoneNumbers: "",
                    }
                    break;
                default:
                    break;
            }
            this.dialogVisible = true;
            this.editType = false;
        },
        signAuditState(val) {
            debugger
            let text = "";
            switch (val) {
                case "AUDIT_STATE_INIT":
                    text = "审核中";
                    break;
                case "AUDIT_STATE_PASS":
                    text = "审核通过";
                    break;
                case "AUDIT_STATE_NOT_PASS":
                    text = "审核未通过";
                    break;
                case "AUDIT_STATE_CANCEL":
                    text = "取消审核";
                    break;

                default:
                    break;
            };
            return text;
        },
        getData() {
            let res = null;
            switch (this.activeName) {
                case "qianMing":
                    {
                        let paramData = JSON.stringify(this.queryData);
                        res = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.QuerySmsSignListWithOptions, ParamJsonString: paramData });
                        break;
                    }
                case "muBan":
                    {
                        let paramData = JSON.stringify(this.queryData);
                        res = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.QuerySmsTemplateListWithOptions, ParamJsonString: paramData });
                        break;
                    }
                case "faSong":
                    {
                        this.queryData.SendDate = dayjs(this.queryData.QueryDate).format("YYYYMMDD");
                        this.queryData.CurrentPage = this.queryData.PageIndex;
                        let paramData = JSON.stringify(this.queryData);
                        res = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.QuerySendDetailsWithOptions, ParamJsonString: paramData });
                        break;
                    }
                default:
                    break;
            }
            res.then(ret => {
                debugger
                let result = JSON.parse(ret.data.ResultData)
                console.log(result.Body.SmsSignList);
                if (result.Body.Code == "OK") {
                    this.queryData.PageIndex = result.Body.CurrentPage ? result.Body.CurrentPage : this.queryData.PageIndex;
                    this.queryData.TotalCount = Number(result.Body.TotalCount);
                    switch (this.activeName) {
                        case "qianMing":
                            this.tableData = result.Body.SmsSignList;
                            break;
                        case "muBan":
                            this.tableData = result.Body.SmsTemplateList;
                            break;
                        case "faSong":
                            this.tableData = result.Body.SmsSendDetailDTOs.SmsSendDetailDTO;
                            break;
                        default:
                            break;
                    }
                }
            })
        },
        getBaseData() {
            SmsApi.smsCount().then(res => {
                debugger
                console.log(1,res);
                this.baseData = res.data.ResultData
            });

            let signTempParams = {
                PageIndex: 1,
                PageSize: 50,
            };
            let signParamData = JSON.stringify(signTempParams);
            SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.QuerySmsSignListWithOptions, ParamJsonString: signParamData }).then(res => {
                let result = JSON.parse(res.data.ResultData)
                if (result.Body.Code == "OK") {
                    this.signOptionData = result.Body.SmsSignList;
                }
            });

            let templateTempParams = {
                PageIndex: 1,
                PageSize: 50,
            };
            let templateParamData = JSON.stringify(templateTempParams);
            SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.QuerySmsTemplateListWithOptions, ParamJsonString: signParamData }).then(res => {
                let result = JSON.parse(res.data.ResultData)
                if (result.Body.Code == "OK") {
                    this.templateOptionData = result.Body.SmsTemplateList;
                }
            });
        },
        saveForm() {
            this.formLoad = true;
            this.$refs.refForm.validate(valid => {
                if (!valid) {
                    this.formLoad = false;
                    return
                }
                let result = null;
                if (this.editType) {
                    switch (this.activeName) {
                        case "qianMing":
                            {
                                let signTempParams = {
                                    SignName: this.formData.SignName,
                                    SignSource: this.formData.SignSource,
                                    SignType: this.formData.SignType,
                                    Remark: this.formData.Remark,
                                };
                                let signParamData = JSON.stringify(signTempParams);
                                result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.ModifySmsSignWithOptions, ParamJsonString: signParamData });
                                break;
                            }
                        case "muBan":
                            {
                                let templateTempParams = {
                                    TemplateCode: this.formData.TemplateCode,
                                    TemplateType: this.formData.TemplateType,
                                    TemplateName: this.formData.TemplateName,
                                    TemplateContent: this.formData.TemplateContent,
                                    Remark: this.formData.Remark,
                                };
                                let templateParamData = JSON.stringify(templateTempParams);
                                result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.ModifySmsTemplateWithOptions, ParamJsonString: templateParamData });
                                break;
                            }
                        default:
                            break;
                    }
                } else {
                    switch (this.activeName) {
                        case "qianMing":
                            {
                                let signTempParams = {
                                    SignName: this.formData.SignName,
                                    SignSource: this.formData.SignSource,
                                    SignType: this.formData.SignType,
                                    Remark: this.formData.Remark,
                                };
                                let signParamData = JSON.stringify(signTempParams);
                                result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.AddSmsSignWithOptions, ParamJsonString: signParamData });
                                break;
                            }
                        case "muBan":
                            {
                                let templateTempParams = {
                                    TemplateType: this.formData.TemplateType,
                                    TemplateName: this.formData.TemplateName,
                                    TemplateContent: this.formData.TemplateContent,
                                    Remark: this.formData.Remark,
                                };
                                let templateParamData = JSON.stringify(templateTempParams);
                                result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.AddSmsTemplateWithOptions, ParamJsonString: templateParamData });
                                break;
                            }
                        case "faSong":
                            {
                                let sendTempParams = {
                                    SignName: this.formData.SignName,
                                    TemplateCode: this.formData.TemplateCode,
                                    TemplateParam: this.formData.TemplateParam,
                                    PhoneNumbers: this.formData.PhoneNumbers,
                                };
                                let sendParamData = JSON.stringify(sendTempParams);
                                result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.SendSmsWithOptions, ParamJsonString: sendParamData });
                                break;
                            }
                        default:
                            break;
                    }
                }
                result.then(res => {
                    let result = JSON.parse(res.data.ResultData)
                    if (result.Body.Code == "OK") {
                        this.$message({
                            type: "success",
                            message: `提交成功！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                        this.handleClose();
                        this.getData();
                    } else {
                        this.$message({
                            type: "error",
                            message: `提交失败！`,
                            duration: 3000,
                            position: "bottom-right"
                        })
                    }
                }).finally(e => {
                    this.formLoad = false;
                })
            })
        },
        handleClose() {
            this.dialogVisible = false;
        },
        deleteClick(data) {
            let result = null;

            switch (this.activeName) {
                case "qianMing":
                    {
                        let signTempParams = {
                            SignName: data.SignName,
                        };
                        let signParamData = JSON.stringify(signTempParams);
                        result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.DeleteSmsSignWithOptions, ParamJsonString: signParamData });
                        break;
                    }
                case "muBan":
                    {
                        let templateTempParams = {
                            TemplateCode: data.TemplateCode,
                        };
                        let templateParamData = JSON.stringify(templateTempParams);
                        result = SmsApi.smsAction({ ActionName: SmsApi.AliSmsActionEnum.DeleteSmsTemplateWithOptions, ParamJsonString: templateParamData });
                        break;
                    }
                default:
                    break;
            }

            result.then(res => {
                let result = JSON.parse(res.data.ResultData)
                if (result.Body.Code == "OK") {
                    this.$message({
                        type: "success",
                        message: `删除成功！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                    this.getData();
                } else {
                    this.$message({
                        type: "error",
                        message: `删除失败！`,
                        duration: 3000,
                        position: "bottom-right"
                    })
                }
            })
        },
        editClick(data) {
            switch (this.activeName) {
                case "qianMing":
                    this.formData = {
                        SignName: data.SignName,
                        SignSource: "",
                        SignType: "",
                        Remark: "",
                    }
                case "muBan":
                    this.formData = {
                        TemplateCode: data.TemplateCode,
                        TemplateType: data.OuterTemplateType,
                        TemplateName: data.TemplateName,
                        TemplateContent: data.TemplateContent,
                        Remark: "",
                    }
                default:
                    break;
            }
            this.dialogVisible = true;
            this.editType = true;
        },
    }
};
</script>

<style>
.input-with-datepicker .el-input-group__prepend {
    padding: 0;
}
</style>


