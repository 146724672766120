<template>
  <div class="bg">
    <div class="bg bg-blur" style="display: none;"></div>
    <div style="height: 20%;"></div>
    <el-form :model="loginForm" :rules="loginFormRules" ref="loginForm" label-position="left" label-width="0px"
      class="demo-ruleForm login-container">
      <h3 class="title">订单管理系统</h3>
      <el-form-item prop="account">
        <el-input type="text" v-model="loginForm.account" auto-complete="off" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" auto-complete="off" show-password placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="primary" :disabled="btnDisabled" style="width:100%;" :loading="logining" @click="handleSubmit">{{
          loginStr }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from "../../apis/login.js";
import router, { resetRouter } from "../../router";
export default {
  data() {
    return {
      btnDisabled: false,
      loginStr: "登录",
      logining: false,
      loginForm: {
        account: "",
        password: ""
      },
      loginFormRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      checked: true
    };
  },
  mounted() {
  },
  methods: {
    ClearSubmit() {
      localStorage.removeItem("Token");
      localStorage.removeItem("UserInfo");
    },
    //登录
    handleSubmit() {
      let _this = this;
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          _this.logining = true;
          _this.btnDisabled = true;
          _this.loginStr = "登录中...";
          var params = {
            UserName: this.loginForm.account,
            Password: this.loginForm.password
          };
          setTimeout(() => {
            login(params).then(res => {
              if (res.data.Success) {
                var userInfo = res.data.ResultData;

                _this.$message({
                  type: "success",
                  message: `登录成功 \n 欢迎你：${userInfo.UserName} ！`,
                  duration: 3000,
                  position: "bottom-right"
                });

                _this.SetInfoByUserInfo(userInfo);

                _this.$router.replace("/user");
              } else {
                _this.$message({
                  message: _this.Global.MsgConvert(res.data.MsgType),
                  type: "error"
                });

                _this.loginStr = "重新登录";
              }
              _this.logining = false;
              _this.btnDisabled = false;
            }).finally(res => {
              _this.logining = false
            });
          }, 100);
        }
      });
    },

    //登录成功，缓存用户信息
    SetInfoByUserInfo(userInfo) {
      //设置Token
      localStorage.setItem("Token", userInfo.Token);
      userInfo.BaseRight = []
      if (userInfo.UserName == "Admin") {
        userInfo.IsAdmin = true;
      }
      if (userInfo.UserName == "Admin" || (userInfo.Right & 1)) {
        userInfo.IsManager = true;
      }
      if (userInfo.UserName == "Admin" || (userInfo.Right & 3)) {
        userInfo.LookOrder = true;
      }
      if (userInfo.UserName == "Admin" || (userInfo.Right & 4)) {
        userInfo.IsSmsManager = true;
      }
      //存储用户信息
      localStorage.setItem("UserInfo", JSON.stringify(userInfo));
      //重置路由
      resetRouter();
    },
  }
};
</script>

<style>
.bg {
  margin: 0px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url(../../assets/loginbck.png) no-repeat top left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.login-container {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: auto;
  width: 500px;
  padding: 35px 35px 15px 35px;
  background-color: #19395e;
  border: 1px solid #2858a5;
  box-shadow: 0 0 25px #cac6c6;
  z-index: 9999;
  position: relative;
}

.login-container .title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #265185;
  font-weight: 900;
  font-size: 20px;
}

.login-container .remember {
  margin: 0px 0px 25px 0px;
}
</style>
