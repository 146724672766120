
import request from './request'
const apiUrl="/api/Product/"

//添加 所有用户都可添加
export function addProduct(data) {
    return request({
        url: apiUrl+'AddProduct',
        method: 'POST',
        data:data,
    })
}

//删除 高级管理员
export function deleteProduct(data) {
    return request({
        url: apiUrl+'DeleteProduct',
        method: 'POST',
        data:data,
    })
}

//获取所有订单 按Time倒序 高级管理员与被授予查看权限的用户
export function getAllProduct() {
    return request({
        url: apiUrl+'GetAllProduct',
        method: 'GET',
    })
}

//获取订单列表（分页） 按Time倒序 高级管理员与被授予查看权限的用户
export function getProductList(data) {
    return request({
        url: apiUrl+'GetProductList',
        method: 'POST',
        data:data,
    })
}

//修改订单 高级管理员
export function updateProduct(data) {
    return request({
        url: apiUrl+'UpdateProduct',
        method: 'POST',
        data:data,
    })
}
  








