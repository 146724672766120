<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import router from "./router";

export default {
  name: 'App',
  components: {
  },
  mounted() {
    document.title="订单管理系统";
    //this.loadRouter();
  },
  methods: {
    loadRouter() {
      let routerString = localStorage.getItem("Router")
      if (routerString) {
        let routeList = JSON.parse(routerString)
        const routers= this.formatRouter(routeList)
        // 动态添加可访问路由表
        router.addRoute(routers[0]);
        console.log(router.getRoutes());
      }
    },
    formatRouter(routeList) {
     const routers=  routeList.filter(f => {
        f.component =() => import(f.component);//(resolve)=> require([`${f.component}`],resolve);
        if (f.children) {
          f.children = this.formatRouter(f.children)
        }
        return true;
      });
      return routers;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
</style>
